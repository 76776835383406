
.boxes {
  @apply flex flex-wrap items-center {}
  &:not(:first-child) {
    margin-top: -1px; }
  .box {
    @apply border border-blue-200 flex flex-grow h-full items-center p-4 text-xs w-full {}
    @media (min-width: theme('screens.md')) {
      &:not(.md\:w-full) {
        @apply w-full {} } }
    // @media (min-width: theme('screens.lg'))
    //   &:not(.lg\:w-full)
    //     @apply w-1/2
    @media (min-width: theme('screens.xl')) {
      &:not(.xl\:w-full) {
        @apply w-1/2 {} } }
    @media (min-width: theme('screens.2xl')) {
      &:not(.\2xl\:w-full) {
        @apply w-1/3 max-w-xs {} } }
    &-green {
      @apply bg-green-100 border-green-500 {} }
    &-red {
      @apply bg-red-100 border-red-500 {} }
    &:not(:first-child) {
      @media (min-width: theme('screens.2xl')) {
        border-left: none; } }
    > :last-child:not(:first-child) {
      @apply ml-auto {} }
    .badge {
      @apply text-xs {} } } }
